import React from "react";
import CardButton from "../../components/CardButton";
import { StandardLayout } from "../../layout";

export * from "./data-forms";
export const Projects = () => {
  return (
    <StandardLayout suitName={"Clubs"} title={"Projects"}>
      <div className="mt-16 mx-32 px-4">
        <CardButton title="Data Forms" href="/projects/data-forms" />
      </div>
    </StandardLayout>
  );
};
