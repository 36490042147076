import React from "react";
import { StandardLayout } from "../layout";
import { InlineWidget} from "react-calendly"
export const Meet = () => {
  return (
    <StandardLayout suitName={"Hearts"} title={"Meet"}>
      <InlineWidget url="https://calendly.com/mattcollyer/30min?hide_gdpr_banner=1" />
    </StandardLayout>
  );
};
  
