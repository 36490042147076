import React from "react";
import { StandardLayout } from "../../layout";

export const DataForms = () => {
  return (
    <StandardLayout suitName={"Clubs"} title={"Data Forms"}>
      <p className="text-center text-2xl text-zinc-700 font-bold font-me mt-4">
        My senior year at Bennington College was defined by an immersive
        tutorial in Information Theory, a subject I diligently worked towards
        and found immensely engaging. This tutorial proved to be a wonderful
        capstone to my academic journey in Computer Science & Mathematics. For
        my final project - which I consider the essence of my entire senior work
        - I ventured back into the realm of visual arts. Here I brought to life
        a concept that had long captivated my mind. This project merged
        the abstract world of binary data with the tangible medium of ceramic
        forms. These sculptures, crafted from porcelain and encoded with digital
        messages, became my avenue for exploring and visualizing the
        complexities of information in a physical form.
        <br />
        <br />
        <img
          src="/images/data-forms/full-display.jpeg"
          alt="forms on display at bennington college"
        />
        <br />
        Currently on display in the Bennington College science building
        <br />
        <br />
        <video autoplay loop muted controls>
          <source src="/images/data-forms/printing.mp4" type="video/mp4" />
        </video>
        <br />
        Printing in action
        <br />
        <br />
        <video autoplay loop muted controls>
          <source src="/images/data-forms/sculptures.mp4" type="video/mp4" />
        </video>
        <br />
        <br />
        Once, I dozed off on a bag of porcelain waiting for the print to complete.
        <br />
        <img src="/images/data-forms/closeup.jpeg" alt="closeup" />
        <br />
        <br />
        <br />
        <img src="/images/data-forms/description.png" alt="description" />
      </p>
    </StandardLayout>
  );
};
