import { Home, About, Meet, Resume, Projects, DataForms } from "./pages"
import {Routes, Route} from "react-router-dom"
const App = () => {
  return (
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="meet" element={<Meet />} />
        <Route path="projects" element={<Projects />} />
        <Route path="projects/data-forms/" element={<DataForms/>}/>
        <Route path="resume" element={<Resume />} />
    </Routes>

  )
}

export default App;
