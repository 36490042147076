import React from "react";
import { StandardLayout } from "../layout";
export const About = () => {
  return (
    <StandardLayout suitName={"Spades"} title={"About"}>
          <p className="text-center text-3xl text-zinc-700 font-bold font-me mt-4">
          I'm Matt, a software engineer living in beautiful Providence, RI.<br/>
          This site is my personal hub - a place where I document my work and a
          way for people to reach out and connect. 
          My academic roots are in
          Mathematics and Computer Science from Bennington College, where I
          dabbled in the intersection of technology and art. I believe this to be where my passions lie. Lately, my
          creative passions (and the Projects page here) have been gathering
          dust, as I've been channeling my energy into honing my trade as a
          software engineer.
          
          When I'm not clicking and clacking away, I'm embracing the cards in my hand. Such as finding satisfaction in a home
          project, solace in trails, and joy in the ever-present company of my cats. 
          
          
          <br/> <br/> Please, make
          yourself at home.
          </p>
    </StandardLayout>
  );
};
