import { Link } from "react-router-dom";
import React from "react"
export const CardSuit = ({suitName, description, href, size, selected}) => {
     const Suit = () => {
           const className = `${size === "small" ? 'w-24 h-24' : 'w-32 h-32'} justify-center mx-auto ${selected ? '-translate-y-8' : 'transition group-hover:-translate-y-8'}`
           switch(suitName){
                 case "Spades":
                  return  <img src="/images/Spade.svg" alt="spades" className={className}/>
                  case "Diamonds":
                        return  <img src="/images/Diamond.svg" alt="diamonds" className={className}/>
                  case "Clubs":
                        return  <img src="/images/Club.svg" alt="clubs" className={className}/>
                  case "Hearts":
                        return  <img src="/images/Heart.svg" alt="hearts" className={className}/>
                  default:
                        return <></>
           }
           
      }



      const textClassName = `text-center opacity-0 transition group-hover:!opacity-100 ${size === "small" ? 'text-3xl' : 'text-5xl'} text-zinc-700 font-me`
      return (
            <Link to={href ?? ""} className="flex-col group justify-center">
                  <Suit/>
                  { !selected && <div className={textClassName}>
                        {description}
                  </div> }
            </Link>
      )

}
