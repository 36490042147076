import React from 'react';
import { Link } from "react-router-dom";

const CardButton = ({ title, href}) => {
    return (
        <div className="group relative w-full h-full overflow-hidden rounded-lg cursor-pointer">
        <Link to={href}>
        <img src="/images/CardBackHorizontal.jpg" alt="" className="w-full h-full object-cover transition duration-300 ease-in-out group-hover:blur-sm" />
        <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-linear">
            <span className="text-white text-5xl font-bold font-me px-4 py-4">
                {title}
            </span>
        </div>
        </Link>
    </div>
    );
};

export default CardButton;