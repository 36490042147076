import { CardSuit } from "./Suit"

const navigation = [
  {
    suitName:"Spades",
    description:"About",
    href:"/about"
  },
  {
    suitName: "Diamonds",
    description:"Resume",
    href:"/resume"
  },
  {
    suitName: "Clubs",
    description:"Projects",
    href:"/projects"
  },
  {
    suitName: "Hearts",
    description:"Meet",
    href:"/meet"
  }
]
export const Nav = ({size, className, currentPage}) => {
  const containerName = `${className} flex lg:flex-row flex-col flex-center justify-evenly`
  return (
   <div className={containerName}>
    {navigation.map((suit) => <CardSuit {...suit} key={suit.suitName} selected={currentPage === suit.suitName} size={size} />)}
  </div>)
}





// export const SideNav = () => (
//   <div className="flex flex-col justify-evenly absolute left-0">
//     {navigation.map((suit) =><CardSuit key={suit.suitName} className="w-32 h-32 mx-auto transition group-hover:-translate-x-8" {...suit}/>)}
//   </div>
// )
