
import { CardSuit, Nav } from "../components";

export const Home = () => {

return (


  <div className="bg-white h-screen w-screen flex flex-col justify-center">
    <Nav/>
    </div>
)

}

