
import { Nav } from "../components"

export const StandardLayout = ({suitName, title, children}) => {

  const King = () => {
    switch(suitName){
      case "Diamonds":
        return <img src="/images/KingDiamonds.png" alt="king of diamonds" className="invisible lg:visible h-full bg-scroll fixed right-0"/>
      case "Clubs":
        return <img src="/images/KingClubs.png" alt="king of clubs" className="invisible lg:visible h-full bg-scroll fixed right-0"/>
      case "Spades":
        return <img src="/images/KingSpades.png" alt="king of spades" className="invisible lg:visible h-full bg-scroll fixed left-0"/>
      case "Hearts":
        return <img src="/images/KingHearts.png" alt="king of hearts" className="invisible lg:visible h-full bg-scroll fixed left-0"/>
      default:
        return <></>
    }}


    const Weapon = ({flip = false}) => {
      const className = `${flip ? 'rotate-180 mt-52' : ''} visible lg:invisible h-full`
      switch(suitName){
        case "Diamonds":
          return <img alt="" src="/images/DiamondAxe.png" className = {className}/>
        case "Clubs":
          return <img alt="" src="/images/ClubSword.png" className = {className}/>
        case "Spades":
          return <img alt="" src="/images/SpadeSword.png" className = {className}/>
        case "Hearts":
          return <img alt="" src="/images/HeartDagger.png" className = {className}/>
        default:
          return <></>
      }
    }


    const MobileNav = () => (
      <div className = "lg:hidden flex flex-row justify-evenly">
      <Weapon/>
      <Nav size="small"/>
      <Weapon flip={true}/>
      </div>
    )  

    const Page = () => (
      <div className="mt-16 flex flex-col justify-between lg:w-1/2 p-2">
      <MobileNav/>
      <Nav size = "small" className="hidden lg:flex" currentPage={suitName}/>
        <h1 className="text-center text-5xl text-zinc-700 font-me">
          {title}
        </h1>
      <body>
      {children}
      </body>
      </div>
    )

  return (
    <div className="lg:flex lg:flex-row lg:justify-between">
      {(suitName === "Spades" || suitName === "Hearts") &&
      <div>
        <King/>
      </div>
    }
    <Page/>
      {(suitName === "Diamonds" || suitName === "Clubs") &&
      <div>
        <King/>
      </div>
    }


      </div>
  )
}