import React from "react";
  import { StandardLayout } from "../layout";
export const Resume = () => {
  return (
    <StandardLayout suitName={"Diamonds"} title={"Resume"}>
      <iframe class="w-full h-screen" src="/images/resume.pdf" type="application/pdf">
    <p>Your browser does not support PDFs. <a href="your-resume.pdf">Download the PDF</a>.</p>
    </iframe>

    </StandardLayout>


  );
};
  

